import React from "react";
import { Stack } from "@mui/material";
import Header from "../conversation/Header";
import Footer from "../conversation/Footer";
import Message from "../conversation/Message";

const GroupConversation = () => {
  return (
    <Stack direction="column" sx={{ height: "calc(100vh - 80px)", width: "100%" }}>
      <Header isGroupChat={true} />
      <Message isGroupchat={true} />
      <Footer isGroupchat={true} />
    </Stack>
  );
};

export default GroupConversation;
