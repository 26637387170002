import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setupXmppClient } from 'services/xmppConnection';
import { receiveMessage } from 'state/chat';

const extractFileNameFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    return fileName;
  } catch (e) {
    console.error('Invalid URL:', url);
    return 'file';
  }
};

const useXmppClient = () => {
  
  const token = useSelector((state) => state.auth.token);
  const username = useSelector((state) => state.auth.user?.username?.toLowerCase());
  const dispatch = useDispatch();

  useEffect(() => {
    if (!username || !token) {
      console.log('Username or token is missing, XMPP client will not be set up.');
      return;
    }

    const password = token.slice(0, 15);
    // console.log('Setting up XMPP client with username:', username, 'and password:', password);
    const xmppClient = setupXmppClient(username, password);
    xmppClient.on('stanza', (stanza) => {
      console.log('Received stanza:', stanza.toString());

      if (stanza.is('message')) {
        const from = stanza.attrs.from.split('@')[0];
        const to = stanza.attrs.to.split('@')[0];
        const messageType = stanza.attrs.type;

        // Check for empty subject or body and ignore it
    const subject = stanza.getChild('subject');
    const body = stanza.getChild('body');
    
    if (subject && !subject.text() && !body) {
      console.log('Ignoring empty subject message');
      return; // Skip processing this message
    }

    console.log(from);
    

        if (messageType === 'chat' && stanza.getChild('body')) {
          const message = stanza.getChildText('body');
          const urlPattern = new RegExp('^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|bmp|webp))$', 'i');
          const isImageUrl = urlPattern.test(message);

          if (isImageUrl) {
            const fileName = extractFileNameFromUrl(message);
            toast.info(`New image message from ${from}: ${fileName}`);
          } else {
            toast.info(`New message from ${from}: ${message}`);
          }

          dispatch(receiveMessage({ chatType: 'personal', from, to, message }));
        } else if (messageType === 'groupchat') {
          console.log("received group chat");
          const body = stanza.getChild('body');
          const data = stanza.getChild('data');
          const message = body ? body.text() : '';

          const senderFirstName = data ? data.attrs.senderFirstName : '';
          const senderLastName = data ? data.attrs.senderLastName : '';
          const senderJID = data ? data.attrs.senderJID : '';
          const senderWalletAddress = data ? data.attrs.senderWalletAddress : '';
          const roomJid = data ? data.attrs.roomJid : '';
          const isSystemMessage = data ? data.attrs.isSystemMessage : '';
          const tokenAmount = data ? data.attrs.tokenAmount : '';
          const quickReplies = data ? data.attrs.quickReplies : '';
          const notDisplayedValue = data ? data.attrs.notDisplayedValue : '';

          const from = stanza.attrs.from.split('/').pop();

          if (from === username) {
            console.log('Ignoring duplicate message from the current user');
            return;
          }
          
          toast.info(`New group message from ${from}: ${message}`);

          
          dispatch(receiveMessage({
            chatType: 'group',
            from: stanza.attrs.from,
            to,
            message,
            senderFirstName,
            senderLastName,
            senderJID,
            senderWalletAddress,
            roomJid,
            isSystemMessage,
            tokenAmount,
            quickReplies,
            notDisplayedValue
          }));
        }
      } else {
        console.log('Received non-chat stanza.');
      }
    });

    xmppClient.start().catch(console.error);

    return () => {
      if (xmppClient) {
        console.log('Stopping XMPP client.');
        xmppClient.stop().catch(console.error);
      }
    };
  }, [username, token, dispatch]);

  return null;
};

export default useXmppClient;

