import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Search,
  Message,
  Notifications,
  Help,
  Menu,
  Close,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "state";
import { fetchUsersList } from "state/thunks";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import PublicIcon from '@mui/icons-material/Public';
import { persistor } from "index";
import { ToastContainer } from "react-toastify";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const users = useSelector((state) => state.auth.users);
  const fetchUsersListError = useSelector((state) => state.auth.fetchUsersListError);
  const fetchUsersListLoading = useSelector((state) => state.auth.fetchUsersListloading);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  const fullName = user ? `${user.firstName} ${user.lastName}` : "";
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (!fetchUsersListError && users.length === 0) {
      dispatch(fetchUsersList());
    }
  }, [dispatch, fetchUsersListError, users.length]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredUsers(
        users.filter((user) =>
          user.username && user.username.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredUsers([]);
    }
  }, [searchQuery, users]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // const logoutApi = async (fcmToken) => {
  //   try {
  //     const response = await fetch(`${baseURL}/auth/signout`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ fcmToken }),
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       throw new Error(error.message || 'Logout failed');
  //     }

  //     return await response.json();
  //   } catch (error) {
  //     throw new Error(error.message || 'Logout failed');
  //   }
  // };

  const handleLogout = async () => {
    try {
      // await logoutApi();
      localStorage.removeItem("persist:root");
      await persistor.purge();
      await dispatch(setLogout());
    } catch (error) {
      setSnackbarMessage("Not able to sign out. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
    
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
        bgcolor={alt}
        
      >
        <FlexBetween padding="1rem 6%" backgroundColor={alt}>
          <FlexBetween gap="1.75rem">
            <Typography
              fontWeight="bold"
              fontSize="clamp(1rem, 2rem, 2.25rem)"
              color="primary"
              onClick={() => navigate("/home")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              Hypefresh
            </Typography>
            {isNonMobileScreens && (
              <FlexBetween
                backgroundColor={neutralLight}
                borderRadius="9px"
                gap="3rem"
                padding="0.1rem 1.5rem"
                position="relative"
              >
                <InputBase
                  placeholder="Search by username..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <IconButton>
                  <Search />
                </IconButton>
                {searchQuery && (
                  <Box
                    position="absolute"
                    top="100%"
                    left="0"
                    width="100%"
                    maxHeight="300px"
                    padding="1rem 1rem 0.5rem 1rem"
                    overflow="auto"
                    backgroundColor={background}
                    borderRadius="0.5rem"
                    boxShadow={theme.shadows[5]}
                    zIndex="1000"
                  >
                    {fetchUsersListLoading && (
                      <Box display="flex" justifyContent="center" p="1rem">
                        <CircularProgress />
                      </Box>
                    )}
                    {fetchUsersListError && (
                      <Typography color="error" p="1rem">
                        Something went wrong. Please try again later.
                      </Typography>
                    )}
                    {!fetchUsersListError && filteredUsers.length === 0 && !fetchUsersListLoading && (
                      <Typography p="1rem">No users found.</Typography>
                    )}
                    {!fetchUsersListError && filteredUsers.map((user) => (
                      <Friend
                        key={user.username}
                        username={user.username}
                        userPicturePath={user.image}
                        sx={{ cursor: "pointer", p: "0.5rem 1rem", "&:hover": { backgroundColor: neutralLight } }}
                      />
                    ))}
                  </Box>
                )}
              </FlexBetween>
            )}
          </FlexBetween>

          {isNonMobileScreens ? (
            <FlexBetween gap="2rem">
              <IconButton component={Link} to="/globalfeed">
                <PublicIcon sx={{ fontSize: "25px" }} />
              </IconButton>
              <IconButton component={Link} to="/chat/app">
                <Message sx={{ fontSize: "25px" }} />
              </IconButton>
              <IconButton component={Link} to="/notifications">
                <Notifications sx={{ fontSize: "25px" }} />
              </IconButton>
              <FormControl variant="standard" value={fullName}>
                <Select
                  value={fullName}
                  sx={{
                    backgroundColor: neutralLight,
                    width: "150px",
                    borderRadius: "0.25rem",
                    p: "0.25rem 1rem",
                    "& .MuiSvgIcon-root": {
                      pr: "0.25rem",
                      width: "3rem",
                    },
                    "& .MuiSelect-select:focus": {
                      backgroundColor: neutralLight,
                    },
                  }}
                  input={<InputBase />}
                >
                  <MenuItem value={fullName}>
                    <Typography>{fullName}</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                </Select>
              </FormControl>
            </FlexBetween>
          ) : (
            <IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
              <Menu />
            </IconButton>
          )}
        </FlexBetween>
      </Box>

      {/* Padding adjustment for body content */}
      <Box
        sx={{
          paddingTop: "5rem", // Adjust this value based on the height of your navbar
        }}
      >
        {!isNonMobileScreens && isMobileMenuToggled && (
          <Box
            position="fixed"
            right="0"
            bottom="0"
            height="100%"
            zIndex="1100"
            maxWidth="500px"
            minWidth="300px"
            backgroundColor={background}
          >
            <Box display="flex" justifyContent="flex-end" p="1rem">
              <IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
                <Close />
              </IconButton>
            </Box>
            <FlexBetween
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="3rem"
            >
              <IconButton component={Link} to="/globalfeed">
                <PublicIcon sx={{ fontSize: "25px" }} />
              </IconButton>
              <Link to="/chat/app">
                <Message sx={{ fontSize: "25px" }} />
              </Link>
              <Notifications sx={{ fontSize: "25px" }} />
              <Help sx={{ fontSize: "25px" }} />
              <FormControl variant="standard" value={fullName}>
                <Select
                  value={fullName}
                  sx={{
                    backgroundColor: neutralLight,
                    width: "150px",
                    borderRadius: "0.25rem",
                    p: "0.25rem 1rem",
                    "& .MuiSvgIcon-root": {
                      pr: "0.25rem",
                      width: "3rem",
                    },
                    "& .MuiSelect-select:focus": {
                      backgroundColor: neutralLight,
                    },
                  }}
                  input={<InputBase />}
                >
                  <MenuItem value={fullName}>
                    <Typography>{fullName}</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                </Select>
              </FormControl>
            </FlexBetween>
          </Box>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Navbar;
