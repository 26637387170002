import { faker } from "@faker-js/faker";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Bell, CaretRight, Phone, Prohibit, Trash, VideoCamera, X, Plus } from "phosphor-react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar, updateSidebarType } from "state/chat";
import AddMemberForm from "../sections/Dashboard/AddMemberForm"; // Import AddMemberForm
import AntSwitch from "./AntSwitch";
import { fetchGroupMembers } from "state/groupSlice"; // Import fetchGroupMembers

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlockDialog = ({ open, handleClose }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>Block this contact</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to block this Contact?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleClose}>Yes</Button>
    </DialogActions>
  </Dialog>
);

const DeleteDialog = ({ open, handleClose }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>Delete this chat</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to delete this chat?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleClose}>Yes</Button>
    </DialogActions>
  </Dialog>
);

// Utility function to check if a URL is an image
const isImageUrl = (url) => {
  return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
};

// Function to extract and return the latest 3 image URLs from a list of messages
const extractLatestThreeImages = (messages) => {
  return messages
    .filter((msg) => isImageUrl(msg.message))  // Filter messages with image URLs
    .slice(-3)  // Get the latest 3 images
    .map((msg) => msg.message);  // Extract the image URLs
};


const Contact = ({ chatType }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [openBlock, setOpenBlock] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddMember, setOpenAddMember] = useState(false); // State for Add Member dialog
  const token = useSelector((state) => state.auth.token);
  const allUsers = useSelector((state) => state.auth.users);

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseAddMember = () => {
    setOpenAddMember(false);
  };

  const personalRoomId = useSelector((state) => state.sidebar.personal.roomId);
  const groupRoomId = useSelector((state) => state.sidebar.group.roomId);
  const roomId = chatType === 'personal' ? personalRoomId : groupRoomId;

  const user = useSelector((state) =>
    state.auth.users.find((user) => user.id === roomId)
  );
  const group = useSelector((state) =>
    state.groups.groups.find((group) => group.id === roomId)
  );

  const isGroupChat = chatType !== "personal";

  const groupMembers = useSelector((state) => {
    if (state.groups.members && state.groups.members[roomId]) {
      return state.groups.members[roomId];
    }
    return { owner: [], admins: [], members: [] };
  });

  useEffect(() => {
    if (isGroupChat && roomId) {
     
      dispatch(fetchGroupMembers({ groupId: roomId, token }));
    }
  }, [isGroupChat, roomId, dispatch]);

  const getUserImageById = (id) => {
    const user = allUsers.find((user) => user.id === id);
    return user ? user.image : ''; // Return empty string if user is not found
  };

  const messages = useSelector((state) => state.sidebar[chatType].messages);
  
  // Extract the latest 3 images
  const latestImages = extractLatestThreeImages(messages);

  return (
    <Box sx={{ width: 320, height: "calc(100vh - 80px)" }}>
      <Stack sx={{ height: "100%" }}>
        {/* Header */}
        <Box
          sx={{
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            width: "100%",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F8FAFF"
                : theme.palette.background,
          }}
        >
          <Stack
            sx={{ height: "100%", p: 2 }}
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            spacing={3}
          >
            <Typography variant="subtitle2">Contact Info</Typography>
            <IconButton
              onClick={() => {
                dispatch(toggleSidebar({ chatType }));
              }}
            >
              <X />
            </IconButton>
          </Stack>
        </Box>
        {/* Body */}
        <Stack
          sx={{
            height: "100%",
            position: "relative",
            flexGrow: 1,
            overflowY: "scroll",
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-msOverflowStyle': 'none', // IE and Edge
            'scrollbarWidth': 'none', // Firefox
          }}
          p={3}
          spacing={3}
        >
          <Stack alignItems={"center"} direction="row" spacing={2}>
            <Avatar
              src={isGroupChat ? group?.image : user?.image}
              alt={isGroupChat ? group?.naturalName : user?.username}
              sx={{ height: 64, width: 64 }}
            />
            <Stack spacing={0.5}>
              <Typography variant="article" fontWeight={600}>
                {isGroupChat ? group?.naturalName : user?.username}
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {isGroupChat ? "" : user?.mobileNumber}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-evenly"
          >
            <Stack spacing={1} alignItems="center">
              <IconButton>
                <Phone />
              </IconButton>
              <Typography variant="overline">Voice</Typography>
            </Stack>
            <Stack spacing={1} alignItems="center">
              <IconButton>
                <VideoCamera />
              </IconButton>
              <Typography variant="overline">Video</Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={0.5}>
            <Typography variant="article">About</Typography>
            <Typography variant="body2">
              {isGroupChat ? "Imagination is the only limit" : user?.bio}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">Media, Links & Docs</Typography>
            <Button 
              onClick={() => {
                dispatch(updateSidebarType({ chatType, type: 'SHARED' }));
              }}
              endIcon={<CaretRight />}
            >
              401
            </Button>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
          {latestImages.map((image, index) => (
              <Box 
              key={index} 
              sx={{
                width: '100px', 
                height: '100px', 
                overflow: 'hidden', 
                borderRadius: 2,
              }}
            >
              <img 
                src={image} 
                alt={` ${index + 1}`} 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            ))}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Stack direction={"row"} alignItems="center" spacing={2}>
              <Bell size={21} />
              <Typography variant="subtitle2">Mute Notifications</Typography>
            </Stack>
            <AntSwitch />
          </Stack>
          <Divider />
          {isGroupChat && (
            <>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Owner</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
              {groupMembers.owner.map((ownerId) => (
                  <Box key={ownerId}>
                    <Avatar src={getUserImageById(ownerId)} alt={ownerId} />
                    <Typography variant="caption">{ownerId}</Typography>
                  </Box>
                ))}
              </Stack>
              <Divider />
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Admins</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
              {groupMembers.admins.map((adminId) => (
                  <Box key={adminId}>
                    <Avatar src={getUserImageById(adminId)} alt={adminId} />
                    <Typography variant="caption">{adminId}</Typography>
                  </Box>
                ))}
              </Stack>
              <Divider />
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Members</Typography>
                <Button
                  startIcon={<Plus />}
                  onClick={() => setOpenAddMember(true)}
                >
                  Add Member
                </Button>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
              {groupMembers.members.map((memberId) => (
                  <Box key={memberId}>
                    <Avatar src={getUserImageById(memberId)} alt={memberId} />
                    <Typography variant="caption">{memberId}</Typography>
                  </Box>
                ))}
              </Stack>
              <Divider />
            </>
          )}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Stack direction={"row"} alignItems="center" spacing={2}>
              <Prohibit size={21} />
              <Typography variant="subtitle2">Block</Typography>
            </Stack>
            <IconButton onClick={() => setOpenBlock(true)}>
              <CaretRight />
            </IconButton>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Stack direction={"row"} alignItems="center" spacing={2}>
              <Trash size={21} />
              <Typography variant="subtitle2">Delete Chat</Typography>
            </Stack>
            <IconButton onClick={() => setOpenDelete(true)}>
              <CaretRight />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <BlockDialog open={openBlock} handleClose={handleCloseBlock} />
      <DeleteDialog open={openDelete} handleClose={handleCloseDelete} />
      <Dialog open={openAddMember} onClose={handleCloseAddMember}>
        <AddMemberForm 
        groupId={roomId}
        handleClose={handleCloseAddMember}
        currentMembers={[...groupMembers.owner, ...groupMembers.admins, ...groupMembers.members]}
        allUsers={allUsers}
        />
      </Dialog>
    </Box>
  );
};

export default Contact;
