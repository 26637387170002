import React, { useState } from "react";
import {
  DeleteOutlined,
  MoreVert,
  PersonAddOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import { addFollowing, removeFollowing } from "../state/index";
import MessageModal from "scenes/widgets/MessageModel";

const Friend = ({
  username,
  userPicturePath,
  subtitle,
  handleDeleteComment,
  commentId,
  handleDeletePost,
  handleMenuClick,
  handleMenuClose,
  menuAnchorEl,
  menuOpen,
  deletingPost
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const main = palette.neutral.main;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);

  // Get the list of followings and current user from the Redux store
  const followings = useSelector((state) => state.auth.followings);
  const currentUser = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);

  // Check if the current user is already followed
  const isFollowing = followings.some(
    (following) => following.username === username
  );

  // Handle follow/unfollow action
  const handleFollowUnfollow = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/${username}/follow`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setIsError(false);
        setModalMessage(data.message);
        if (isFollowing) {
          dispatch(removeFollowing({ username }));
        } else {
          dispatch(addFollowing({ username, image: userPicturePath }));
        }
      } else {
        setIsError(true);
        setModalMessage(data.message);
      }
    } catch (error) {
      setIsError(true);
      setModalMessage("Something went wrong. Please try again.");
    } finally {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Check if the user is the current logged-in user
  const isCurrentUser = username === currentUser.username;

  return (
    <>
      <FlexBetween>
        <FlexBetween gap="1rem">
          <UserImage image={userPicturePath} size="50px" />
          <Box
            onClick={() => {
              navigate(`/profile/${username}`);
            }}
          >
            <Typography
              color={main}
              variant="h5"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.main,
                  cursor: "pointer",
                },
              }}
            >
              {username}
            </Typography>
            <Typography>{subtitle}</Typography>
          </Box>
        </FlexBetween>
        {!isCurrentUser && (
          <IconButton
            onClick={handleFollowUnfollow}
            sx={{
              "&:hover": {
                color: palette.primary.main,
              },
            }}
          >
            {isFollowing ? <PersonRemoveOutlined /> : <PersonAddOutlined />}
          </IconButton>
        )}
        {isCurrentUser && commentId && (
          <>
            <IconButton
              onClick={() => handleDeleteComment(commentId)}
              sx={{
                ml: "auto",
                "&:hover": {
                  color: palette.primary.main,
                },
              }}
            >
              <DeleteOutlined />
            </IconButton>
          </>
        )}
          {isCurrentUser && handleDeletePost && (
        <IconButton onClick={handleMenuClick}>
          <MoreVert />
        </IconButton>    
      )}
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleDeletePost}>
          {deletingPost ? <CircularProgress size={24} /> : <DeleteOutlined />}
          <Typography sx={{ ml: 1 }}>Delete</Typography>
        </MenuItem>
      </Menu>
      </FlexBetween>

      <MessageModal
        open={modalOpen}
        handleClose={handleCloseModal}
        message={modalMessage}
        isError={isError}
      />
    </>
  );
};

export default Friend;
