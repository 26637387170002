import { Avatar, Badge, Box, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { selectConversation, fetchChatHistory } from "state/chat"; // Add fetchChatHistory

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledChatBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

const ChatElement = ({
  id,
  name,
  img,
  msg,
  time,
  unread,
  online,
  chatType, // 'personal' or 'group'
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const nickname = useSelector((state) => state.auth.user.username)

  const handleClick = () => {
    dispatch(selectConversation({ chatType, roomId: id, name, chatOpen: "individual", nickname: nickname }));
    dispatch(fetchChatHistory({ oppositeUserId: id, chatType, token: token })); // Add token properly
  };

  return (
    <StyledChatBox
      onClick={handleClick}
      sx={{
        width: "100%",
        borderRadius: 1,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.background.default
            : theme.palette.background.paper,
      }}
      p={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          {online ? (
            // <StyledBadge
            //   overlap="circular"
            //   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            //   variant="dot"
            // >
              <Avatar src={img} />
            // </StyledBadge>
          ) : (
            <Avatar src={img} />
          )}

          <Stack spacing={0.3}>
            <Typography variant="subtitle2">{name}</Typography>
            <Typography variant="caption">{msg}</Typography>
          </Stack>
        </Stack>
        <Stack spacing={2} alignItems="center">
          <Typography
            sx={{ fontWeight: 600 }}
            variant="caption"
            className="font"
          >
            {time}
          </Typography>
          <Badge
            color="primary"
            badgeContent={unread}
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: theme.palette.primary.main,
              },
            }}
          />
        </Stack>
      </Stack>
    </StyledChatBox>
  );
};

export default ChatElement;
