import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData, fetchPosts, fetchUsersList } from "./thunks";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  posts: { data: [] },
  userPosts: [],
  followers: [],
  followings: [],
  users: [],
  fetchUsersListError: false,
  fetchUsersListLoading: false,
  fetchUserDataLoading: false,
  fetchUserDataError: null,
  fetchPostsLoading: false,
  fetchPostsError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
    addFollower: (state, action) => {
      state.followers.push(action.payload);
    },
    removeFollower: (state, action) => {
      state.followers = state.followers.filter(
        (follower) => follower.username !== action.payload.username
      );
    },
    addFollowing: (state, action) => {
      state.followings.push(action.payload);
    },
    removeFollowing: (state, action) => {
      state.followings = state.followings.filter(
        (following) => following.username !== action.payload.username
      );
    },
    addUserPost: (state, action) => {
      state.userPosts.push(action.payload);
    },
    removeUserPost: (state, action) => {
      state.userPosts = state.userPosts.filter(
        (post) => post.id !== action.payload.id
      );
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    addPost: (state, action) => {
      state.posts.data.push(action.payload);
    },
    removePost: (state, action) => {
      state.posts.data = state.posts.data.filter((post) => post.id !== action.payload.id);
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    likePost: (state, action) => {
      if (state.user) {
        state.user.likes.push(action.payload);
      }
    },
    unlikePost: (state, action) => {
      if (state.user) {
        state.user.likes = state.user.likes.filter(
          (like) => like.postId !== action.payload.postId
        );
      }
    },
    addLikeCount: (state, action) => {
      const postId = action.payload;
      const updateLikeCount = (posts) => {
        const post = posts.find((post) => post.id === postId);
        if (post) {
          post._count.likedBy += 1;
        }
      };

      updateLikeCount(state.posts.data);
      updateLikeCount(state.userPosts);
    },
    removeLikeCount: (state, action) => {
      const postId = action.payload;
      const updateLikeCount = (posts) => {
        const post = posts.find((post) => post.id === postId);
        if (post) {
          post._count.likedBy -= 1;
        }
      };

      updateLikeCount(state.posts.data);
      updateLikeCount(state.userPosts);
    },
    addCommentCount: (state, action) => {
      const postId = action.payload;
      const updateCommentCount = (posts) => {
        const post = posts.find((post) => post.id === postId);
        if (post) {
          post._count.comments += 1;
        }
      };

      updateCommentCount(state.posts.data);
      updateCommentCount(state.userPosts);
    },
    removeCommentCount: (state, action) => {
      const postId = action.payload;
      const updateCommentCount = (posts) => {
        const post = posts.find((post) => post.id === postId);
        if (post) {
          post._count.comments -= 1;
        }
      };

      updateCommentCount(state.posts.data);
      updateCommentCount(state.userPosts);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.fetchUserDataLoading = true;
        state.fetchUserDataError = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.fetchUserDataLoading = false;
        state.user = action.payload;
        state.followers = action.payload.followers;
        state.followings = action.payload.following;
        state.userPosts = action.payload.posts;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.fetchUserDataLoading = false;
        state.fetchUserDataError = action.payload;
      })
      .addCase(fetchPosts.pending, (state) => {
        state.fetchPostsLoading = true;
        state.fetchPostsError = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.fetchPostsLoading = false;
        state.posts = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.fetchPostsLoading = false;
        state.fetchPostsError = action.payload;
      })
      .addCase(fetchUsersList.pending, (state) => {
        state.fetchUsersListLoading = true;
        state.fetchUsersListError = false;
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.fetchUsersListLoading = false;
        state.users = action.payload; // Assuming action.payload is an array of users
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.fetchUsersListLoading = false;
        state.fetchUsersListError = action.payload;
      });
  },
});

export const {
  setMode,
  setLogin,
  setLogout,
  addFollower,
  removeFollower,
  addFollowing,
  removeFollowing,
  addUserPost,
  removeUserPost,
  addPost,
  removePost,
  setUsers,
  updateUser,
  likePost,
  unlikePost,
  addLikeCount,
  removeLikeCount,
  addCommentCount,
  removeCommentCount,
} = authSlice.actions;

export default authSlice.reducer;
