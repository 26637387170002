import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Grid } from "@mui/material";
import Form from "./Form";
import logo from "../../assets/logo.png";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <Box height={"100%"} alignContent={"center"}>
      <Grid container
        width={isNonMobileScreens ? "80%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
        spacing={4}
      >
        <Grid item md={4} sx={{ display: isNonMobileScreens ? 'flex' : 'none' }} justifyContent="center" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
            />
            <Typography fontWeight="500" variant="h3" sx={{ mt: "1.5rem" }}>
              HypeFresh is an online magazine curating entertainment related content and celebrity news to the world.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isNonMobileScreens ? 8 :12}>
          <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
            Welcome to Hypefresh Social App.
          </Typography>
          <Form />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
