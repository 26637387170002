import { useSelector } from "react-redux";
import PostWidget from "./PostWidget";
import { CircularProgress, Typography, Box } from "@mui/material";

const PostsWidget = ({ isProfile = false }) => {
  const {
    fetchPostsLoading,
    fetchPostsError,
    fetchUserDataLoading,
    fetchUserDataError,
    userPosts,
    posts,
  } = useSelector((state) => state.auth);

  if (isProfile) {
    if (fetchUserDataLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (fetchUserDataError) {
      return <Typography variant="body1" color="error">{fetchUserDataError}</Typography>;
    }

    if (!userPosts || userPosts.length === 0) {
      return <Typography variant="body1">No posts available.</Typography>;
    }

  } else {
    if (fetchPostsLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (fetchPostsError) {
      return <Typography variant="body1" color="error">{fetchPostsError}</Typography>;
    }

    if (!posts?.data || posts.data.length === 0) {
      return <Typography variant="body1">No posts available.</Typography>;
    }
  }

  const postList = isProfile ? userPosts : posts.data;

  return (
    <>
      {postList.map(
        ({
          id,
          title,
          content,
          author,
          _count, // Object containing comments and likedBy
        }) => (
          <PostWidget
            key={id}
            username={author.username}
            postId={id}
            title={title}
            name={`${author?.firstName || ""} ${author?.lastName || ""}`}
            postImage={content}
            userPicturePath={author?.image || ""}
            likes={_count?.likedBy || 0}
            comments={_count?.comments || 0}
          />
        )
      )}
    </>
  );
};

export default PostsWidget;
