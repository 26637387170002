import React from "react";
import { Stack } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import Message from "./Message";

const Conversation = () => {
  return (
    <Stack direction="column" sx={{ height: "calc(100vh - 80px)", width: "100%" }}>
      <Header />
      <Message isGroupchat={false} />
      <Footer isGroupchat={false} />
    </Stack>
  );
};

export default Conversation;
