import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from "socket.io-client";
import { setCaller, setCallInProgress, setIsCalling, setIsReceivingCall } from 'state/call';
import peer from "services/peer";

const SocketContext = createContext(null);

export const useSocket = () => {
    return useContext(SocketContext);
}

export const SocketProvider = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const userId = user ? user.id : null;
    const {calleeInfo} = useSelector((state) => state.call)
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (userId) {
            const newSocket = io("https://api-c.hypefresh.com", {
                query: { userId },
            });

            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Socket connected:', newSocket.id);
                newSocket.emit('register', { userId });
                console.log(`User registered: ${userId}`);
            });

            newSocket.on('connect_error', (err) => {
                console.error('Socket connection error:', err);
            });

            newSocket.on('disconnect', (reason) => {
                console.log('Socket disconnected:', reason);
            });

            newSocket.on('offer', async (payload) => {
                const offer = payload.offer;
                console.log(payload);
                
                console.log("offer",offer);
                dispatch(setIsReceivingCall(true));
                dispatch(setCaller(payload))
            });

            newSocket.on('answer', async (payload) =>{
                console.log("call accepted");
                console.log(payload.answer);
                dispatch(setIsCalling(false));
                dispatch(setCallInProgress(true));
                peer.setRemoteDescription(payload.answer);
            })


            return () => {
                newSocket.disconnect();
                newSocket.off('connect');
                newSocket.off('connect_error');
                newSocket.off('disconnect');
                newSocket.off('offer');
                newSocket.off('answer');
                
            };
        }
    }, [userId, dispatch]);



    return (
        <SocketContext.Provider value={socket}>
            {props.children}
        </SocketContext.Provider>
    );
}
