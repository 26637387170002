import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { LoginSocialFacebook, LoginSocialTwitter } from "reactjs-social-login";
import {
  FacebookLoginButton,
  TwitterLoginButton,
} from "react-social-login-buttons";

import { setLogin } from "state";
import ForgotPasswordModal from "scenes/widgets/ForgotPasswordModel";

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const [signUploading, setSignUploading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [signUpError, setSignUpError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    mobileNumber: "",
    countryCode: "",
    password: "",
  });
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";

  // API URL
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormValues((prevFormData) => ({
      ...prevFormData,
      mobileNumber: value,
    }));
  };

  const register = async () => {
    setSignUploading(true);
    try {
      const response = await fetch(`${baseURL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      const data = await response.json();
      if (response.ok) {
        setPageType("login");
      } else {
        setLoginLoading(false);
        setSignUpError(data.message);
      }
    } catch (error) {
      setSignUpError("Something went wrong. Please try again.");
    }
    setSignUploading(false);
  };

  const login = async () => {
    setLoginLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formValues.email,
          password: formValues.password,
        }),
      });
      const resObj = await response.json();
      if (response.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
      } else {
        setLoginLoading(false);
        setLoginError(resObj.message);
      }
    } catch (error) {
      setLoginError("Something went wrong. Please try again.");
    }
    setLoginLoading(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) await login();
    if (isRegister) await register();
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const backendResponse = await fetch(`${baseURL}/auth/google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: response.credential }),
      });
      const resObj = await backendResponse.json();
      if (backendResponse.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
      } else {
        setLoginError(resObj.message);
      }
    } catch (error) {
      console.error("Google login error:", error);
      setLoginError("Google login failed. Please try again.");
    }
  };

  const handleFacebookSuccess = async (response) => {
    try {
      console.log("Facebook response:", response.data.accessToken);

      const backendResponse = await fetch(`${baseURL}/auth/facebook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: response.data.accessToken }),
      });

      const resObj = await backendResponse.json();
      console.log("Backend response status:", backendResponse.status);
      console.log("Backend response body:", resObj);

      if (backendResponse.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
      } else {
        console.error("Backend error:", resObj.message);
        setLoginError(resObj.message);
      }
    } catch (error) {
      console.error("Facebook login error:", error);
      setLoginError("Facebook login failed. Please try again.");
    }
  };

  const handleTwitterSuccess = async (response) => {
    try {
      console.log("Twitter response:", response);
      const backendResponse = await fetch(`${baseURL}/auth/twitter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: response.oauth_token,
          token_secret: response.oauth_token_secret,
        }),
      });
      const resObj = await backendResponse.json();
      if (backendResponse.ok) {
        dispatch(
          setLogin({ user: resObj.data.user, token: resObj.data.access_token })
        );
        navigate(`/home`);
      } else {
        setLoginError(resObj.message);
      }
    } catch (error) {
      console.error("Twitter login error:", error);
      setLoginError("Twitter login failed. Please try again.");
    }
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <>
        <form onSubmit={handleFormSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isRegister && (
              <>
                <TextField
                  label="First Name"
                  onChange={handleInputChange}
                  value={formValues.firstName}
                  name="firstName"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Last Name"
                  onChange={handleInputChange}
                  value={formValues.lastName}
                  name="lastName"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="username"
                  onChange={handleInputChange}
                  value={formValues.username}
                  name="username"
                  sx={{ gridColumn: "span 2" }}
                />
                <Box sx={{ gridColumn: "span 2" }}>
                  <PhoneInput
                    placeholder="Your Phone Number"
                    id="mobileNumber"
                    country={"us"}
                    value={formValues.mobileNumber}
                    onChange={handlePhoneChange}
                    name="mobileNumber"
                  />
                </Box>
              </>
            )}

            <TextField
              label="Email"
              onChange={handleInputChange}
              value={formValues.email}
              name="email"
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Password"
              type="password"
              onChange={handleInputChange}
              value={formValues.password}
              name="password"
              sx={{ gridColumn: "span 4" }}
            />
          </Box>
          <Typography
            onClick={() => setForgotPasswordOpen(true)}
            sx={{
              textDecoration: "underline",
              color: palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                color: palette.primary.light,
              },
            }}
          >
            Forgot Password?
          </Typography>
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
              disabled={signUploading || loginLoading}
            >
              {isLogin ? "LOGIN" : "REGISTER"}
            </Button>
            {isLogin && (
              <Box>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={() => {
                    console.log("Login Failed");
                    setLoginError("Google login failed. Please try again.");
                  }}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      sx={{
                        width: "100% !important", // Full width of the parent container
                        maxWidth: "400px !important", // Optional: Limit maximum width
                        minWidth: "150px !important", // Optional: Ensure a minimum width
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px !important", // Ensure consistent padding
                        border: "1px solid #4285F4",
                        borderRadius: "4px",
                        color: "#4285F4",
                        backgroundColor: "transparent",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#f1f3f4",
                        },
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                        alt="Google logo"
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      Continue with Google
                    </Button>
                  )}
                />

                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onResolve={handleFacebookSuccess}
                  onReject={(error) => {
                    console.error("Facebook login error:", error);
                    setLoginError("Facebook login failed. Please try again.");
                  }}
                >
                  <FacebookLoginButton />
                </LoginSocialFacebook>

                <LoginSocialTwitter
                  consumerKey={process.env.REACT_APP_TWITTER_CONSUMER_KEY}
                  consumerSecret={process.env.REACT_APP_TWITTER_CONSUMER_SECRET}
                  accessToken=""
                  accessTokenSecret=""
                  onResolve={handleTwitterSuccess}
                  onReject={(error) => {
                    console.error("Twitter login error:", error);
                    setLoginError("Twitter login failed. Please try again.");
                  }}
                >
                  <TwitterLoginButton />
                </LoginSocialTwitter>
              </Box>
            )}
            <Typography
              onClick={() => {
                setPageType(isLogin ? "register" : "login");
                setSignUpError(null);
                setLoginError(null);
              }}
              sx={{
                textDecoration: "underline",
                color: palette.primary.main,
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,
                },
              }}
            >
              {isLogin
                ? "Don't have an account? Sign Up here."
                : "Already have an account? Login here."}
            </Typography>
          </Box>
        </form>
        <ForgotPasswordModal
          open={forgotPasswordOpen}
          handleClose={() => setForgotPasswordOpen(false)}
        />
      </>
    </GoogleOAuthProvider>
  );
};

export default Form;
