import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import authReducer from "./state";
import sidebarReducer from "./state/chat";
import groupReducer from "./state/groupSlice"; // Import the new slice
import callReducer from "./state/call";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

// Combine reducers into a root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  groups: groupReducer, // Add the group slice here
  call: callReducer,
});

// Persist configuration with blacklist
const persistConfig = {
  key: "root",
  storage,
  version: 1,
  blacklist: ["call"], // Blacklist the call slice
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor for managing the persistence
export const persistor = persistStore(store);

// Render the application with Redux and Redux Persist
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
