import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography, IconButton, Tabs, Tab, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CaretLeft } from "phosphor-react";
import { updateSidebarType } from "state/chat";
import { DocMsg, LinkMsg } from "./conversation/MsgTypes";
import { SHARED_DOCS, SHARED_LINKS } from "../data";

// Assuming messages are already in Redux state
const SharedMessages = ({ chatType }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isImageUrl = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
  };
  
  // Function to extract image URLs from a list of messages
  const extractImageUrls = (messages) => {
    return messages
      .filter((msg) => isImageUrl(msg.message))  // Filter messages with image URLs
      .map((msg) => msg.message);  // Extract the image URLs
  };
  const messages = useSelector((state) => state.sidebar[chatType].messages);

  // Get image URLs
  const sharedImages = extractImageUrls(messages);

  return (
    <Box sx={{ width: 320, height: "calc(100vh - 80px)" }}>
      <Stack sx={{ height: "100%" }}>
        {/* Header */}
        <Box
          sx={{
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            width: "100%",
            backgroundColor:
              theme.palette.mode === "light"
                ? "#F8FAFF"
                : theme.palette.background,
          }}
        >
          <Stack
            sx={{ height: "100%", p: 2 }}
            direction="row"
            alignItems={"center"}
            spacing={3}
          >
            <IconButton
              onClick={() => {
                dispatch(updateSidebarType({ chatType, type: 'CONTACT' }));
              }}
            >
              <CaretLeft />
            </IconButton>
            <Typography variant="subtitle2">Shared Messages</Typography>
          </Stack>
        </Box>

        <Tabs
          sx={{ px: 2, pt: 2 }}
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab label="Media" />
          <Tab label="Links" />
          <Tab label="Docs" />
        </Tabs>

        {/* Body */}
        <Stack
          sx={{
            height: "100%",
            position: "relative",
            flexGrow: 1,
            overflowY: "scroll",
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-msOverflowStyle': 'none', // IE and Edge
            'scrollbarWidth': 'none', // Firefox
          }}
          p={3}
          spacing={value === 1 ? 1 : 3}
        >
          {(() => {
            switch (value) {
              case 0:
                // Display images in a grid
                return (
                  <Grid container spacing={2}>
                    {sharedImages.map((image, index) => (
                      <Grid item xs={4} key={index}>
                        <img
                          src={image}
                          alt={`Shared image ${index + 1}`}
                          style={{ width: '100%', borderRadius: '8px' }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                );

              case 1:
                // Links
                return SHARED_LINKS.map((el) => <LinkMsg el={el} />);

              case 2:
                // Docs
                return SHARED_DOCS.map((el) => <DocMsg el={el} />);

              default:
                break;
            }
          })()}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SharedMessages;
