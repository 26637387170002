import { client, xml } from '@xmpp/client';
import debug from '@xmpp/debug';
import { store } from '../index';
import { setXmppConnected } from 'state/chat';

let xmpp;

const setupXmppClient = (username, password) => {
  xmpp = client({
    service: 'wss://vmi1124696.contaboserver.net:7443/ws/',
    domain: 'vmi1124696.contaboserver.net',
    resource: `example-${Date.now()}`,
    username: username,
    password: password,
  });

  debug(xmpp, true);

  xmpp.on('error', (err) => {
    console.error('XMPP Error:', err);
    store.dispatch(setXmppConnected(false));
  });

  xmpp.on('status', (status) => {
    console.log('XMPP Status:', status);
    if (status === 'online') {
      store.dispatch(setXmppConnected(true));
    } else if (status === 'offline') {
      store.dispatch(setXmppConnected(false));
    }
  });

  xmpp.on('online', (address) => {
    console.log('Online as:', address.toString());
    store.dispatch(setXmppConnected(true));
    // Send presence stanza to indicate availability
    const presence = xml('presence');
    xmpp.send(presence);
  });

  xmpp.on('offline', () => {
    console.log('XMPP offline');
    store.dispatch(setXmppConnected(false));
  });

  xmpp.on('stanza', (stanza) => {
    console.log('Incoming stanza:', stanza.toString());
  });

  xmpp.start().catch((error) => {
    console.error('XMPP client start error:', error);
    store.dispatch(setXmppConnected(false)); 
  });

  return xmpp;
};

const joinGroupChat = (roomJid, nickname) => {

  if (!xmpp) {
    console.error('XMPP client is not initialized.');
    return;
  }

  if (!roomJid || !nickname) {
    console.log('Room JID and nickname must not be null or undefined.');
    return;
  }

  const domain = 'conference.vmi1124696.contaboserver.net';
  console.log('Joining room:', roomJid, 'with nickname:', nickname);

  const joinRoom = xml(
    'presence',
    { to: `${roomJid}@${domain}/${nickname}` },
    xml('x', { xmlns: 'http://jabber.org/protocol/muc' },
      xml('history', { maxstanzas: '0' }) // Request no history
    )
  );

  xmpp.send(joinRoom)
    .then(() => {
      console.log('Join room presence sent with no history:', joinRoom.toString());
    })
    .catch((error) => {
      console.error('Error sending join room presence:', error);
    });
};


export { setupXmppClient, xmpp, xml, joinGroupChat };
