import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import ReactPlayer from "react-player";
import { closeModal, setCallInProgress, setIsReceivingCall } from "state/call";
import { useSocket } from "context/SocketProvider";
import peer from "services/peer";
import { toast } from "react-toastify";

const InCallModal = () => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const {
    modalOpen,
    isCalling,
    isReceivingCall,
    callInProgress,
    calleeInfo,
    caller,
  } = useSelector((state) => state.call);
  const currentUser = useSelector((state) => state.auth.user);
  const [micOn, setMicOn] = useState(true);
  const [videoOn, setVideoOn] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);

  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  useEffect(() => {
    if (modalOpen && !isReceivingCall) {
      handleCallUser();
    }
    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [modalOpen]);

  useEffect(() => {
    if (socket && peer.peer) {
      console.log("Socket and Peer connection are established.");

      // Listen for ICE candidates from the peer
      peer.peer.onicecandidate = (event) => {
        if (event.candidate) {
          console.log("Generated local ICE candidate:", event.candidate);
          socket.emit("ice-candidate", {
            candidate: event.candidate,
            callerId: calleeInfo?.id || caller?.user?.id,
          });
          console.log("Sent local ICE candidate to server:", {
            candidate: event.candidate,
            callerId: calleeInfo?.id || caller?.user?.id,
          });
        } else {
          console.log("ICE candidate gathering completed.");
        }
      };

      // Listen for remote track addition
      peer.peer.addEventListener("track", (event) => {
        const remoteStream = event.streams[0];
        console.log("Received remote track:", remoteStream);
        setRemoteStream(remoteStream);
      });

      // Listen for incoming ICE candidates from the other peer
      socket.on("ice-candidate", (payload) => {
        console.log("Received ICE candidate from server:", payload.candidate);
        peer.peer
          .addIceCandidate(new RTCIceCandidate(payload.candidate))
          .then(() => {
            console.log("Successfully added received ICE candidate.");
          })
          .catch((error) => {
            console.error("Error adding received ICE candidate:", error);
          });
      });

      socket.on("call-ended", (payload) => {
        console.log("Call has been ended by the other party:", payload.user.name);
        toast.info(`call has been ended by ${payload.user.name}`);
        
        localStream?.getTracks().forEach((track) => track.stop());
        dispatch(closeModal());
        setLocalStream(null);
        setRemoteStream(null);
        setMicOn(true);
        setVideoOn(true);
        
      });

      socket.on("call-declined", (payload) => {
        console.log("Call was declined by the other party:", payload);
        toast.info(`Call was declined by ${payload.user.name}`);
        localStream?.getTracks().forEach((track) => track.stop());
        dispatch(closeModal());
        setLocalStream(null);
        setMicOn(true);
        setVideoOn(true);
        
      });

      return () => {
        console.log("Cleaning up ICE candidate listeners.");
        socket.off("ice-candidate");
        socket.off("call-ended");
        socket.off("call-declined");
      };
    } else {
      if (!socket) {
        console.log("Socket is not initialized yet.");
      }
      if (!peer.peer) {
        console.log("Peer connection is not yet established.");
      }
    }
  }, [socket, calleeInfo, caller]);

  const handleCallUser = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);
      peer.addLocalStream(stream);

      const offer = await peer.getOffer();
      const payload = {
        callerId: calleeInfo.id,
        offer,
        user: {
          id: currentUser.id,
          name: currentUser.username,
        },
      };

      socket.emit("offer", payload);
    } catch (error) {
      console.error("Error accessing media devices:", error);
      handleMediaError(error);
    }
  };

  const handleMediaError = (error) => {
    if (error.name === "NotAllowedError") {
      alert("Permission to access media devices was denied.");
    } else if (error.name === "NotFoundError") {
      alert("No media devices found. Please check your camera and microphone.");
    } else {
      alert("An error occurred while accessing media devices.");
    }
  };

  const handleAnswerCall = async () => {
    try {
      dispatch(setIsReceivingCall(false));
      dispatch(setCallInProgress(true));

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);

      console.log("Local Stream:", stream);
      peer.addLocalStream(stream);

      const answer = await peer.getAnswer(caller.offer);

      const payload = {
        callerId: caller.user.id, // ID of the caller
        answer, // The generated WebRTC answer
        user: {
          id: currentUser.id,
          name: currentUser.username,
        },
      };

      socket.emit("answer", payload);
    } catch (error) {
      console.error("Error answering call:", error);
      handleMediaError(error);
    }
  };

  const handleDeclineCall = () => {
    const payload = {
      callerId: caller?.user?.id || calleeInfo?.id,
      user: {
        id: currentUser.id,
        name: currentUser.username,
      },
    };

    socket.emit("decline-call", payload);
    localStream?.getTracks().forEach((track) => track.stop());
    dispatch(closeModal());
    setLocalStream(null);
    setMicOn(true);
    setVideoOn(true);
  };

  const handleEndCall = () => {
    const payload = {
      callerId: caller?.user?.id || calleeInfo?.id,
      user: {
        id: currentUser.id,
        name: currentUser.username,
      },
    };

    socket.emit("end-call", payload);
    localStream?.getTracks().forEach((track) => track.stop());
    dispatch(closeModal());
    setLocalStream(null);
    setRemoteStream(null);
    setMicOn(true);
    setVideoOn(true);
  };

  const toggleMic = () => {
    setMicOn((prev) => !prev);
    localStream
      ?.getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const toggleVideo = () => {
    setVideoOn((prev) => !prev);
    localStream
      ?.getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const renderCallStateText = () => {
    if (isCalling) {
      return `Calling ${calleeInfo?.name || "Unknown User"}...`;
    } else if (isReceivingCall) {
      return `Incoming call from ${caller?.user?.name || "Unknown User"}...`;
    } else if (callInProgress) {
      return `You are in a call with ${
        calleeInfo?.name || caller?.user?.name || "Unknown User"
      }.`;
    } else {
      return "";
    }
  };

  return (
    <Modal open={modalOpen}>
      <Box sx={{ ...modalStyle, width: 600 }}>
        <Typography variant="h6" component="h2">
          In Call
        </Typography>
        <Typography sx={{ mt: 2 }}>{renderCallStateText()}</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          {localStream && (
            <Box sx={{ width: "45%" }}>
              <ReactPlayer
                url={localStream}
                playing
                muted
                ref={localVideoRef}
                width="100%"
                height="100%"
              />
            </Box>
          )}
          {remoteStream && (
            <Box sx={{ width: "45%" }}>
              <ReactPlayer
                url={remoteStream}
                playing
                ref={remoteVideoRef}
                width="100%"
                height="100%"
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          {isReceivingCall ? (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                  mx: 1,
                }}
                onClick={handleAnswerCall}
              >
                Answer Call
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                  mx: 1,
                }}
                onClick={handleDeclineCall}
              >
                Decline Call
              </Button>
            </>
          ) : (
            (isCalling || callInProgress) && (
              <>
                <IconButton onClick={toggleMic}>
                  {micOn ? <MicIcon /> : <MicOffIcon />}
                </IconButton>
                <IconButton onClick={toggleVideo}>
                  {videoOn ? <VideocamIcon /> : <VideocamOffIcon />}
                </IconButton>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "darkred",
                    },
                    mx: 1,
                  }}
                  onClick={handleEndCall}
                >
                  End Call
                </Button>
              </>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default InCallModal;
