import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import HomePage from 'scenes/homePage';
import LoginPage from 'scenes/loginPage';
import ProfilePage from 'scenes/profilePage';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import Chat from 'scenes/Chat';
import Notifications from 'scenes/Notifications';
import Help from 'scenes/Help';
import Page404 from 'scenes/Chat/pages/Page404';
import Layout from 'Layout';
import GeneralApp from 'scenes/Chat/pages/dashboard/GeneralApp';
import Group from 'scenes/Chat/pages/dashboard/Group';
import ForgotPassword from 'scenes/forgetPass';
import GlobalFeedWidget from 'scenes/widgets/GlobalFeedWidget';
import { SocketProvider } from 'context/SocketProvider';
import useXmppClient from 'scenes/Chat/hooks/useXmppClient';
import InCallModal from 'scenes/Chat/callmodels/InCallModal';


function App() {
  useXmppClient();
  const mode = useSelector((state) => state.auth.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.auth.token));
  return (
    <div className="app">
      <SocketProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <InCallModal />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Layout />}>
                <Route path="home" element={isAuth ? <HomePage /> : <Navigate to="/" />} />
                <Route path="profile/:username" element={isAuth ? <ProfilePage /> : <Navigate to="/" />} />
                <Route path="chat" element={isAuth ? <Chat /> : <Navigate to="/" />}>
                  <Route path="app" element={isAuth ? <GeneralApp />: <Navigate to="/" />} />
                  <Route path="group" element={isAuth ?<Group />: <Navigate to="/" />} />
                  <Route path="404" element={<Page404 />} />
                  <Route path="*" element={<Navigate to="404" />} />
                </Route>
                <Route path="notifications" element={isAuth ? <Notifications /> : <Navigate to="/" />} />
                <Route path="globalfeed" element={isAuth ? <GlobalFeedWidget /> : <Navigate to="/" />} />
                <Route path="help" element={isAuth ? <Help /> : <Navigate to="/" />} />
              </Route>
              <Route path="404" element={<Page404 />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </SocketProvider>
    </div>
  );
}

export default App;
