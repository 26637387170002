import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { xmpp, xml, joinGroupChat } from '../services/xmppConnection';
import { toast } from 'react-toastify';

const initialState = {
  personal: {
    open: false,
    type: 'CONTACT',
    chatType: 'personal',
    roomId: null,
    chatOpen: null,
    messages: [],
    messageIds: [],
    name: null,
    hasMoreMessages: true,
    offset: 0,
    limit: 20,
    loading: false,
  },
  group: {
    open: false,
    type: 'CONTACT',
    chatType: 'group',
    roomId: null,
    chatOpen: null,
    messages: [],
    messageIds: [],
    name: null,
    hasMoreMessages: true,
    offset: 0,
    limit: 20,
    loading: false,
  },
  xmppConnected: false,
};

// Function to extract username from JID
const extractUsername = (jid) => {
  const [username] = jid.split('@');
  return username;
};

export const fetchChatHistory = createAsyncThunk(
  'chat/fetchChatHistory',
  async ({ oppositeUserId, chatType, token }, { getState, rejectWithValue }) => {
    const { limit, offset } = getState().sidebar[chatType];

    let endpoint;
    if (chatType === 'personal') {
      endpoint = `${process.env.REACT_APP_BASE_URL}/users/${oppositeUserId}/chat-history`;
    } else if (chatType === 'group') {
      endpoint = `${process.env.REACT_APP_BASE_URL}/groups/${oppositeUserId}/chat-history`;
    }

    try {
      const response = await fetch(`${endpoint}?limit=${limit}&offset=${offset}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch chat history: ${errorText}`);
      }
      const data = await response.json();
      console.log('API called', data);

      const messages = chatType === 'personal'
        ? data.map(item => ({
            id: item.sentdate,
            from: extractUsername(item.fromjid),
            to: extractUsername(item.tojid),
            message: item.body,
          }))
        : data.message.map(item => ({
            id: item.delay_stamp,
            from: item.from,
            to: item.to,
            message: item.body,
          }));

      return { chatType, messages };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      const { chatType } = action.payload;
      state[chatType].open = !state[chatType].open;
    },
    updateSidebarType: (state, action) => {
      const { chatType, type } = action.payload;
      state[chatType].type = type;
    },
    selectConversation: (state, action) => {
      const { chatType, roomId, name, chatOpen, nickname } = action.payload;
      state[chatType].roomId = roomId;
      state[chatType].name = name;
      state[chatType].chatOpen = chatOpen;
      state[chatType].messages = [];
      state[chatType].messageIds = [];
      state[chatType].offset = 0;
      state[chatType].hasMoreMessages = true;

      if (chatType === 'group' && xmpp) { 
          joinGroupChat(roomId, nickname);
      }
    },
    sendMessage: (state, action) => {
      const { chatType, name, username, message, groupDetails } = action.payload;

      if (!state.xmppConnected) {
        console.log('XMPP is not connected. Cannot send message.');
        toast.error("XMPP is not connected. Cannot send message.");
        return;
      }

      const domain = 'vmi1124696.contaboserver.net';
      const domain2 = 'conference.vmi1124696.contaboserver.net';
      const jid = chatType === 'group' ? `${groupDetails.roomJid}@${domain2}` : `${name.toLowerCase()}@${domain}`;

      const newMessage = {
        id: new Date().getTime(),
        from: username,
        to: name,
        message,
        senderFirstName: groupDetails.firstName,
        senderLastName: groupDetails.lastName,
        senderJID: jid,
        senderWalletAddress: groupDetails.walletAddress,
        roomJid: groupDetails.roomJid,
        isSystemMessage: "",
        tokenAmount: "",
        quickReplies: "",
        notDisplayedValue: groupDetails.notDisplayedValue,
      };

      console.log(newMessage);

      if (!state[chatType].messageIds.includes(newMessage.id)) {
        if (chatType === 'group') {
          state[chatType].messages.push(newMessage); // Add to the end for group chat
        } else {
          state[chatType].messages = [newMessage, ...state[chatType].messages]; // Add to the beginning for personal chat
        }
        state[chatType].messageIds.push(newMessage.id);
      }

      if (xmpp) {
        const msg = chatType === 'group' ? xml(
          'message',
          {
            to: jid,
            type: "groupchat",
            id: "sendMessage",
          },
          xml("data", {
            senderFirstName: groupDetails.firstName,
            senderLastName: groupDetails.lastName,
            photoURL: groupDetails.photo,
            senderJID: xmpp.jid?.toString(),
            senderWalletAddress: groupDetails.walletAddress,
            roomJid: groupDetails.roomJid,
            isSystemMessage: false,
            tokenAmount: 0,
            quickReplies: [],
            notDisplayedValue: groupDetails.notDisplayedValue ?? "",
          }),
          xml("body", {}, message),
        ) : xml(
          'message',
          { type: 'chat', to: jid },
          xml('body', {}, message),
        );

        xmpp.send(msg)
          .then(() => {
            console.log(`Message sent successfully to ${jid}`);
          })
          .catch(error => {
            console.error('Error sending XMPP message:', error);
          });
      } else {
        console.error('XMPP client is not initialized.');
      }
    },
    receiveMessage: (state, action) => {
      const {
        chatType,
        from,
        to,
        message,
        senderFirstName,
        senderLastName,
        senderJID,
        senderWalletAddress,
        roomJid,
        isSystemMessage,
        tokenAmount,
        quickReplies,
        notDisplayedValue,
      } = action.payload;

      const newMessage = {
        id: new Date().getTime(),
        from,
        to,
        message,
        senderFirstName,
        senderLastName,
        senderJID,
        senderWalletAddress,
        roomJid,
        isSystemMessage,
        tokenAmount,
        quickReplies,
        notDisplayedValue,
      };

      if (!state[chatType].messageIds.includes(newMessage.id)) {
        if (chatType === 'group') {
          state[chatType].messages.push(newMessage); // Add to the end for group chat
        } else {
          state[chatType].messages = [newMessage, ...state[chatType].messages]; // Add to the beginning for personal chat
        }
        state[chatType].messageIds.push(newMessage.id);
      }
    },
    setXmppConnected: (state, action) => {
      state.xmppConnected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatHistory.fulfilled, (state, action) => {
        const { chatType, messages } = action.payload;
        const newMessages = messages.filter(msg => !state[chatType].messageIds.includes(msg.id));
        newMessages.forEach(msg => state[chatType].messageIds.push(msg.id));
        state[chatType].messages = [...newMessages, ...state[chatType].messages];
        state[chatType].offset += newMessages.length;
        if (newMessages.length < state[chatType].limit) {
          state[chatType].hasMoreMessages = false;
        }
      })
      .addCase(fetchChatHistory.rejected, (state, action) => {
        console.error('Failed to fetch chat history:', action.payload);
      });
  },
});

export const { toggleSidebar, updateSidebarType, selectConversation, sendMessage, receiveMessage, setXmppConnected } = slice.actions;
export default slice.reducer;
