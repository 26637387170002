import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ImageModal from "../ImageModal";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { styled, useTheme } from "@mui/material/styles";
import { fetchChatHistory } from "state/chat";

const AudioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  borderRadius: 20,
  padding: "8px 30px",
  maxWidth: "100%",
  position: "relative",
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(1),
}));

const AudioWaveform = styled(Box)(({ theme }) => ({
  width: "60%",
  height: "20px",
  background:
    "linear-gradient(to right, #4caf50 20%, #8bc34a 40%, #cddc39 60%, #ffeb3b 80%)",
  borderRadius: 10,
  marginRight: 8,
  flexShrink: 0,
}));

const Message = ({ isGroupchat }) => {
  const chatType = isGroupchat ? "group" : "personal";
  const token = useSelector((state) => state.auth.token);
  const name = useSelector((state) => state.sidebar[chatType].name);
  const messages = useSelector((state) => state.sidebar[chatType].messages);
  const offset = useSelector((state) => state.sidebar[chatType].offset);
  const oppositeUserId = useSelector((state) => state.sidebar[chatType].roomId);
  const hasMoreMessages = useSelector(
    (state) => state.sidebar[chatType].hasMoreMessages
  );
  const loading = useSelector((state) => state.sidebar[chatType].loading);
  const currentuser = useSelector((state) => state.auth.user.username);
  const theme = useTheme();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const [openImage, setOpenImage] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [playingAudio, setPlayingAudio] = useState(null);
  const [audioDuration, setAudioDuration] = useState({});
  const [audioPlayingUrl, setAudioPlayingUrl] = useState(null);

  const audioRefs = useRef({});

  // useEffect(() => {
  //   const container = containerRef.current;

  //   const handleScroll = () => {
  //     if (container.scrollTop === 0 && hasMoreMessages && !loading) {
  //       console.log("scrollbar reached top");

  //       dispatch(
  //         fetchChatHistory({
  //           oppositeUserId,
  //           chatType,
  //           token,
  //         })
  //       );
  //       console.log("called scrolling api");
  //     }
  //   };

  //   !isGroupchat && container.addEventListener("scroll", handleScroll);

  //   return () => {
  //     container.removeEventListener("scroll", handleScroll);
  //   };
  // }, [dispatch, chatType, hasMoreMessages, oppositeUserId, token, loading]);

  useEffect(() => {
    return () => {
      if (playingAudio) {
        playingAudio.pause();
        setPlayingAudio(null);
      }
    };
  }, [playingAudio]);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleImageClick = (url) => {
    setSelectedImageUrl(url);
    setOpenImage(true);
  };

  const handlePlayPauseAudio = (url) => {
    if (audioPlayingUrl === url) {
      if (playingAudio) {
        playingAudio.pause();
        setPlayingAudio(null);
        setAudioPlayingUrl(null);
      }
    } else {
      if (playingAudio) {
        playingAudio.pause();
        setPlayingAudio(null);
      }

      const audio = new Audio(url);
      audioRefs.current[url] = audio;

      audio.addEventListener("loadedmetadata", () => {
        setAudioDuration((prevDurations) => ({
          ...prevDurations,
          [url]: audio.duration,
        }));
      });

      audio.addEventListener("play", () => {
        setPlayingAudio(audio);
        setAudioPlayingUrl(url);
      });

      audio.addEventListener("pause", () => {
        setPlayingAudio(null);
        setAudioPlayingUrl(null);
      });

      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  };

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const displayedMessages = isGroupchat ? messages : [...messages].reverse();

  return (
    <>
      <Box
        ref={containerRef}
        p={3}
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-msOverflowStyle": "none",
          "scrollbarWidth": "none",
          height: "78vh",
          backgroundColor: "f0f4fa",
        }}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 2,
            }}
          >
            <CircularProgress color="error" />
          </Box>
        )}
        <Stack spacing={3}>
          {displayedMessages.map((el) => {
            const isSentByCurrentUser = isGroupchat
              ? el.from?.includes(currentuser)
              : el.from === name;

            return (
              <Box
                key={el.id}
                sx={{
                  alignSelf: isGroupchat
                    ? isSentByCurrentUser
                      ? "flex-end"
                      : "flex-start"
                    : isSentByCurrentUser
                    ? "flex-start"
                    : "flex-end",
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isGroupchat && !isSentByCurrentUser && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.text.secondary,
                      marginBottom: 0.5,
                      alignSelf: "flex-start",
                    }}
                  >
                    {el.from.split("/").pop()}
                  </Typography>
                )}
                {isValidUrl(el.message) ? (
                  el.message.endsWith(".webm") ||
                  el.message.endsWith(".mp3")||
                  el.message.endsWith(".aac") ? (
                    <AudioContainer>
                      <Box
                        sx={{ flex: 1, display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginRight: 1 }}
                        >
                          {audioDuration[el.message]
                            ? formatDuration(audioDuration[el.message])
                            : "..."}
                        </Typography>
                        <IconButton
                          onClick={() => handlePlayPauseAudio(el.message)}
                          sx={{ padding: 1 }}
                        >
                          {audioPlayingUrl === el.message ? (
                            <PauseIcon />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </IconButton>
                      </Box>
                    </AudioContainer>
                  ) : el.message.endsWith(".mp4") ||
                    el.message.endsWith(".mov") ||
                    el.message.endsWith(".avi") ||
                    el.message.endsWith(".wmv") ? (
                    <Box
                      component="video"
                      controls
                      src={el.message}
                      sx={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: 2,
                        maxHeight: "300px",
                        objectFit: "cover",
                        cursor: "pointer",
                        marginBottom: 1,
                      }}
                    />
                  ) : (
                    <Box
                      component="img"
                      src={el.message}
                      alt="Link as image"
                      sx={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: 2,
                        maxHeight: "300px",
                        objectFit: "cover",
                        cursor: "pointer",
                        marginBottom: 1,
                      }}
                      onClick={() => handleImageClick(el.message)}
                    />
                  )
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      backgroundColor: isGroupchat
                        ? isSentByCurrentUser
                          ? theme.palette.primary.main
                          : theme.palette.grey[300]
                        : isSentByCurrentUser
                        ? theme.palette.grey[300]
                        : theme.palette.primary.main,
                      color: isGroupchat
                        ? isSentByCurrentUser
                          ? "white"
                          : "black"
                        : isSentByCurrentUser
                        ? "black"
                        : "white",
                      borderRadius: 2,
                      padding: 1.5,
                      maxWidth: "100%",
                      wordBreak: "break-word",
                    }}
                  >
                    {el.message}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Stack>
      </Box>

      <ImageModal
        open={openImage}
        handleClose={() => setOpenImage(false)}
        imageUrl={selectedImageUrl}
      />
    </>
  );
};

export default Message;
