import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from 'react-redux';

const Notifications = () => {
  const { palette } = useTheme();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector(state => state.auth.token);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseURL}/notifications`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Something went wrong. Please try again later.');
        }

        const data = await response.json();
        setNotifications(data);
      } catch (err) {
        setError(err.message || 'Something went wrong. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [baseURL, token]);

  return (
    <div>
      
      <Box display="flex" justifyContent="center" minHeight="100vh">
        <WidgetWrapper width="100%" maxWidth="600px" mt="1rem">
          <Box>
            {loading && <CircularProgress />}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && notifications.length === 0 && <Typography>No notifications available</Typography>}
            {!loading && !error && notifications.length > 0 && (
              <Box>
                {notifications.map((notification) => (
                  <Box key={notification.id} mb="1rem">
                    <Typography variant="h6" color={palette.primary.dark}>
                      {notification.type}
                    </Typography>
                    <Typography color={palette.neutral.dark}>
                      {notification.message}
                    </Typography>
                    <Typography color={palette.neutral.main} variant="body2">
                      {new Date(notification.createdAt).toLocaleString()}
                    </Typography>
                    <Divider sx={{ mt: 1 }} />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </WidgetWrapper>
      </Box>
    </div>
  );
};

export default Notifications;
