import PostWidget from "./PostWidget";
import { CircularProgress, Typography, Box, useTheme } from "@mui/material";
import { SentimentDissatisfied } from "@mui/icons-material";

const ProfilePostsWidget = ({ fetchUserDataLoading, fetchUserDataError, user }) => {
  const { palette } = useTheme();

  if (fetchUserDataLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (fetchUserDataError) {
    return (
      <Typography variant="body1" color="error">
        {fetchUserDataError}
      </Typography>
    );
  }

  if (!user || !user.posts || user.posts.length === 0) {
    return (
      <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="200px"
        textAlign="center"
        bgcolor={palette.background.default} // Background color
        borderRadius="8px" // Rounded corners
        boxShadow={3} // Shadow effect
        p="2rem" // Padding
        m="2rem 0" // Margin
      >
        <SentimentDissatisfied
          style={{ fontSize: 100, color: palette.primary.main }}
        />
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{ mt: 2 }}
        >
          No posts available.
        </Typography>
      </Box>
      </>
      
    );
  }

  return (
    <Box>
      {user.posts.map(
        ({
          id,
          title,
          content,
          author,
          _count, // Object containing comments and likedBy
        }) => (
          <PostWidget
            key={id}
            username={author.username}
            postId={id}
            title={title}
            name={`${author?.firstName || ""} ${author?.lastName || ""}`}
            postImage={content}
            userPicturePath={author?.image || ""}
            likes={_count?.likedBy || 0}
            comments={_count?.comments || 0}
          />
        )
      )}
    </Box>
  );
};

export default ProfilePostsWidget;
