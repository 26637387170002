import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PostWidget from "./PostWidget";
import { CircularProgress, Typography, Box, Button, TextField } from "@mui/material";
import useDebounce from "../../hooks/Debounce";

const GlobalFeedWidget = () => {
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 500); // Use debounce hook
  const inputRef = useRef(null); // Create a ref for the input field

  const token = useSelector((state) => state.auth.token);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const fetchGlobalFeedPosts = async (page, searchQuery, isInitialLoad = false) => {
    if (isInitialLoad) {
      setLoadingInitial(true);
    } else {
      setLoadingMore(true);
    }

    try {
      const response = await fetch(`${baseURL}/feeds/search?page=${page}&limit=${limit}&query=${encodeURIComponent(searchQuery)}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch global feed posts.");
      }
      const data = await response.json();
      setPosts((prevPosts) => (page === 1 ? data.feeds : [...prevPosts, ...data.feeds]));
    } catch (error) {
      setError(error.message);
    } finally {
      if (isInitialLoad) {
        setLoadingInitial(false);
      } else {
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    setPage(1); // Reset page to 1 when search query changes
    setPosts([]); // Clear posts before fetching new ones
    fetchGlobalFeedPosts(1, debouncedSearchQuery, true); // Fetch posts with initial page and debounced query
  }, [debouncedSearchQuery]); // Use debounced search query

  useEffect(() => {
    if (page > 1) {
      fetchGlobalFeedPosts(page, debouncedSearchQuery); // Fetch more posts for pagination
    }
  }, [page]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={"1rem"}>
      <TextField
        variant="outlined"
        placeholder="Search posts..."
        value={searchQuery}
        onChange={handleSearchChange}
        inputRef={inputRef} // Set the ref to the input field
        sx={{ marginBottom: "1rem", width: "100%", maxWidth: "600px" ,borderRadius: 4,
            backgroundColor: "background.paper"}}
      />
      {loadingInitial && posts.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">{error}</Typography>
      ) : !posts || posts.length === 0 ? (
        <Typography variant="body1">No posts available.</Typography>
      ) : (
        posts.map(
          ({
            id,
            title,
            content,
            author,
            _count, // Object containing comments and likedBy
          }) => {
            let postImage = "";
            try {
              const parsedContent = JSON.parse(content);
              if (Array.isArray(parsedContent) && parsedContent.length > 0) {
                postImage = parsedContent[0].url;
              }
            } catch (e) {
              postImage = content;
            }

            return (
              <Box
                maxWidth="600px"
                width="100%"
                marginY={3}
                p={2}
                borderRadius={4}
                sx={{ backgroundColor: "background.paper" }}
                key={id}
              >
                <PostWidget
                  username={author.username}
                  postId={id}
                  title={title}
                  name={`${author?.firstName || ""} ${author?.lastName || ""}`}
                  postImage={postImage}
                  userPicturePath={author?.image || ""}
                  likes={_count?.likedBy || 0}
                  comments={_count?.comments || 0}
                />
              </Box>
            );
          }
        )
      )}
      <Box display="flex" justifyContent="center" mt="1rem">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPage((prevPage) => prevPage + 1)}
          disabled={loadingMore}
        >
          {loadingMore ? <CircularProgress size={24} /> : "Load More"}
        </Button>
      </Box>
    </Box>
  );
};

export default GlobalFeedWidget;
